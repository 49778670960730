<template>
  <div>
    <h1>確認</h1>
    <div>
      <a href="https://dashboard.stripe.com/test/customers">STRIPE支払い確認</a>
      <div v-for="(user, index) in users" :key="index" class="one">
        <h2>メールアドレス:{{ user.email }}</h2>
        <div class="btn">
          <div class="ok" @click="test(`${user.id}`)">済</div>
          <div class="not" @click="yet(`${user.id}`)">未</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase/firebase'

export default {
  name: 'Stripe',
  data () {
    return {
      db: null,
      users: [],
      load: 0,
      id: '',
      idMain: ''
    }
  },
  created () {
    this.db = firebase.firestore()
    this.dbTwo = firebase.firestore()
    this.syncContent()
  },
  methods: {
    syncContent () {
      this.db
        .collection('customers')
        .where('subscribe', '==', 0)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.users.push(doc.data())
          })
        })
    },
    test (number) {
      this.db
        .collection('customers')
        .doc(number)
        .update({
          subscribe: 1
        })
    },
    yet (number) {
      this.db
        .collection('customers')
        .doc(number)
        .collection('checkout_session')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.id = doc.id
            this.idMain = number
          })
        })
      this.dbTwo
        .collection('customers')
        .doc(number)
        .update({
          subscribe: 2
        })
    }
  },
  watch: {
    id: function () {
      this.db
        .collection('customers')
        .doc(this.idMain)
        .collection('checkout_session')
        .doc(this.id)
        .delete()
      this.load += 1
    },
    load: function () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.one {
  border: 1px solid black;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding: 5px 10px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.one h2 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
}

.ok {
  background-color: red;
  color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
}

.not {
  background-color: blue;
  color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 5px;
}

.btn {
  display: flex;
}
</style>
